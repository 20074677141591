import Modal from '@/components/common/AppModal/Modal'
import { useAppSelector } from '@/hooks/redux'
import { setDappLoginModalVisible } from '@/redux/reducers/globalsSlice'
import { Unlock } from '@/wallet-integration/components/Unlock'
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnlyOnUpdate } from '../hooks/useEffectOnlyOnUpdate'

const LoginModal: React.FC = () => {
  const dispatch = useDispatch()

  const isLoggedIn = useGetIsLoggedIn()

  const dappLoginModalVisible = useAppSelector(
    state => state.globalsReducer.dappLoginModalVisible,
  )

  useEffectOnlyOnUpdate(() => {
    if (isLoggedIn) {
      dispatch(setDappLoginModalVisible(false))
    }
  }, [dispatch, isLoggedIn])

  const toggle = () => {
    dispatch(setDappLoginModalVisible(!dappLoginModalVisible))
  }

  return (
    <Modal isVisible={dappLoginModalVisible} setIsVisible={toggle} className='max-w-650'>
      <h3>Connect your wallet</h3>
      <Unlock />
    </Modal>
  )
}

export default LoginModal
