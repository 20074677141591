import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
  type ExtensionLoginButtonPropsType,
  type LedgerLoginButtonPropsType,
  type WalletConnectLoginButtonPropsType,
  type WebWalletLoginButtonPropsType,
} from '@multiversx/sdk-dapp/UI'
import { useRouter } from 'next/router'

type CommonPropsType =
  | ExtensionLoginButtonPropsType
  | WebWalletLoginButtonPropsType
  | LedgerLoginButtonPropsType
  | WalletConnectLoginButtonPropsType

export const Unlock = () => {
  const router = useRouter()

  const callbackRoute = window.location.pathname
  const commonProps: CommonPropsType = {
    callbackRoute: callbackRoute,
    nativeAuth: true,
    onLoginRedirect: () => {
      router.push(callbackRoute)
    },
  }

  //TODO: if possible overwrite Login Buttons styles instead of putting buttuns inside
  return (
    <div className='flex flex-col items-center'>
      <WalletConnectLoginButton
        className='w-100 p-0 !border-none !bg-transparent'
        showScamPhishingAlert={false}
        wrapContentInsideModal={true}
        {...commonProps}
      >
        <button className='btn btn-primary w-100'>xPortal Mobile App</button>
      </WalletConnectLoginButton>
      <LedgerLoginButton
        className='w-100 p-0 !border-none !bg-transparent'
        showScamPhishingAlert={false}
        wrapContentInsideModal={true}
        {...commonProps}
      >
        <button className='btn btn-primary w-100'>Ledger</button>
      </LedgerLoginButton>
      <ExtensionLoginButton
        className='w-100 p-0 !border-none !bg-transparent'
        {...commonProps}
      >
        <button className='btn btn-primary w-100'>MultiversX Browser Extension</button>
      </ExtensionLoginButton>
      <WebWalletLoginButton
        className='w-100 p-0 !border-none !bg-transparent'
        {...commonProps}
      >
        <button className='btn btn-primary w-100'>MultiversX Web Wallet</button>
      </WebWalletLoginButton>
    </div>
  )
}
